.header-main-container {
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 40px;
    box-sizing: border-box;
    flex-direction: column;
    color: white;
    font-weight: 200;
}

.header-sony-container {
    background-color: black;
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0rem 8rem;
    box-sizing: border-box;
}

.header-bassnet-container {
    width: 100%;
    height: 60%;
    background-color: var(--dark-grey);
    border-radius: 10px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0px 1rem 0px 0px;
    position: relative;
}

.header-logo-and-name-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    gap: 0.4vw;
}

.header-logo-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-logo-background {
    height: 100%;
    width: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--red);
    border-radius: 10px;
}

.header-help-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    margin-right: 0.3rem;
}

.helper-text-main-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    font-size: 18px;
    gap: 1.4rem;
}

.helper-text-up-container {
    width: 100%;
    height: 50%;
}

.helper-text-down-container {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.helper-text-down-box-container {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.helper-text-keyboard-container {
    width: 100%;
    height: auto;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
}