.inpaint-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    border-radius: 10px;
    background-color: var(--dark-grey);
    width: 45vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 7%;
    height: 30vh;
    color: white;
}

.inpaint-modal-main-content {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    overflow-x: scroll;
}

.inpaint-modal-waveform {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.inpaint-modal-timeline-container {
    position: relative;
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inpaint-progress-timeline {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    width: 42vw;
    margin-top: 1vw;
    justify-content: flex-start;
}

.inpaint-modal-buttons-container {
    display: flex;
    flex-direction: row;
    gap: 5%;
    align-items: center;
    justify-content: center;
    height: 15%;
    width: 100%;
    margin-bottom: 0.6vw;
}

.inpaint-modal-button {
    border-radius: 10px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    padding: 1%;
    background-color: var(--black);
    cursor: pointer;
}

.inpaint-modal-main {
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.inpaint-modal-waveform-container {
    /* overflow: auto;
    width: 100%;
    height: 80%; */
    width: 100%;
    height: 100%;
    /* height: 70%; */
    /* display: flex; */
    align-items: center;
    justify-content: center;
    font-size: 50px;
    gap: 0.5rem;
    transition: all 0.5s ease-out;
}

.inpaint-modal-zoom-container {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    justify-content: space-around;
    width: 10%;
    height: 15%;
}

.inpaint-modal-progress-timestamps-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow-x: hidden;
    font-size: 0.7vw;
    font-weight: 600;
    opacity: 0.3;
    font-family: 'Fira Sans', sans-serif;
    color: white;
    position: absolute;
}

.inpaint-modal-progress-timeline {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: 0;
}
