.bassline-element-border-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0%;
    margin-bottom: 0.15rem;
}

.generating-bassline-slider {
    position: absolute;
    display: flex;
    height: 100%;
    overflow: hidden;
    background-size: 35px 35px;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: cssProgressActive;
    animation-timeline: auto;
    animation-range-start: normal;
    animation-range-end: normal;
    background-image : linear-gradient(-45deg, rgba(255,255,255,0.125) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.125) 50%, rgba(255,255,255,0.125) 75%, transparent 75%, transparent);
}

.bassline-element-main-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
    position: static;
    flex-direction: row;
    padding: 0.6vw 0.8vw;
}

.bassline-element-main {
    display: flex;
    flex-direction: flex-start;
    align-items: center;
    gap: 0.4rem;
}

.bassline-waveform-container {
    width: 100%;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: -1%;
    margin-right: 0.2rem;
}

.bassline-player-download-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
}

.bassline-player-icon {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bassline-download-icon {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bassline-middle-infos {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.28vw;
}

.bassline-title {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.55vw;
    font-weight: 600;
    color: var(--white);
}

.bassline-foldin-icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bassline-foldin-icon svg {
    transition: all 0.3s ease-in-out;
}

.bassline-duration-text {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.6vw;
    color: var(--white);
}

.bassline-color-title {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.6vw;
    color: var(--white);
}

.bassline-color-infos {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: cneter;
    font-size: 0.75rem;
    color: var(--white);
    gap: 0.5rem;
}

.bassline-color-box {
    display: flex;
    width: 1rem;
    height: 1rem;
    border-radius: 0.125rem;
}

.bassline-more-infos {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--white);
}

.bassline-elemnt-remove-icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bassline-folded-add-button-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--light-grey);
    padding: 0.3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.84375rem;
    gap: 0.15rem;
}

.bassline-folded-add-button-title {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.55vw;
    font-weight: 600;
    color: var(--white);
}

.bassline-folded-add-button-icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bassline-tooltip-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.1vw;
    font-weight: 800;
    text-decoration: underline;
    gap: 0.1rem;
}

.bassline-infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.2rem;
    font-size: 1vw;
}

@keyframes cssProgressActive {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 35px 35px;
    }
}