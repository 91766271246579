@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200;300;400;500;600;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap');


body {
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Works on Firefox */
* {
  scrollbar-color: var(--black) transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px  !important;
  background-color: transparent !important;
}

*::-webkit-scrollbar-track {
  background-color: transparent !important;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--black) !important;
  border-radius: 20px !important;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--dark-black) !important;
  cursor: pointer !important;
}

:root {
  --dark-grey: #28272c;
  --dark-grey-bis: #36353A;
  --black: #1A191E;
  --dark-black: #141415;
  --light-grey: #3F4043;
  --shiny-grey: #757575;
  --blue: #3B3650;
  --shiny-purple: #7762CB;
  --dark-pink: #722940;
  --orange: #FF9473;
  --light-pink: #FF6D97;
  --light-yellow: #EDD039;
  --dark-yellow: #DB9C3B;
  --navy-blue: #197FD2;
  --lightGreen: #008F74;
  --red: #A61932;
  --orangeBrown: #CA5022;
  --pink: #CA4872;
  --salmon: #CF8977;
  --lowIntenseOrange : #F5A623;
  --yellow: #D3A958;
  --darkSalmon: #B96365;
}
