.generic-dropdown-container {
  background-color: transparent;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  padding: 0.4vw;
  gap: 1vw;
}

.generic-dropdown-list {
  position: absolute;
  bottom: 100%;
  width: 100%;
  background-color: #3f4043;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: fit-content;
  z-index: 100;
}

.generic-dropdown-item-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.generic-dropdown-item {
  width: 100%;
  padding-block: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 8%;
}

.generic-dropdown-item:hover {
  background-color: #1a191e;
}

.generic-dropdown-dots-container {
  border-radius: 50px;
  background-color: black;
  height: 2vh;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generic-dropdown-modal {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 14vw;
  height: 100%;
  padding-left: 2%;
  border-radius: 10px;
  background-color: #1a191e;
}
