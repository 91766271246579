.horizontal-slider-container-main-container {
    display: flex;
    width: 100%;
    height: 55%;
    box-sizing: border-box;
    padding: 0px 0.1rem;
}

.horizontal-slider-container-top-part {
    width: 100%;
    height: 20%;
    display: flex;
}

.horizontal-slider-container-type-container {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    background-color: var(--darkSalmon);
}

.horizontal-slider-container-type-container:hover {
    font-weight: 700;
}

.horizontal-slider-container-bottom-part {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
