.horizontal-slider-container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.horizontal-slider-gauge-container {
    display: flex;
    height: 35%;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.horizontal-slider-gauge-container-right-side {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
}

.horizontal-slider-gauge-container-left-side {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.horizontal-slider-input-container {
    display: flex;
    width: 100%;
    height: 50%;
    align-items: center;
    justify-content: center;
}

/* input[type="range"].horizontal-slider-input-container {

    display: flex;
    width: 65%;
    overflow: hidden;

    align-items: center;
    justify-content: center;

    background: #1A191E no-repeat center;
} */
