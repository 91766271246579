.light-text {
  font-family: "Fira Sans";
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 300;
}

.generic-button {
  background-color: var(--purple);
  border-radius: 10px;
  padding: 2%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px 0px #1a191e;
}

.shadow {
  box-shadow: 0px 2px 4px 0px #1a191e;
}

.underline {
  min-height: 1px;
  width: 100%;
  background-color: #48484d;
}

.generic-button-no-hover {
  background-color: var(--purple);
  border-radius: 10px;
  padding: 2%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px 0px #1a191e;
}

.generic-button:hover {
  cursor: pointer;
  background-color: var(--darkPurple);
}

.generic-small-bold-text {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 600;
}

.generic-small-text {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 400;
}

.generic-medium-bold-text {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 600;
}

.generic-medium-text {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 400;
}

.generic-big-bold-text {
  color: #fff;
  text-align: center;
  font-family: "Fira Sans";
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 900;
}

.generic-xl-bold-text {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 2.8vw;
  font-style: normal;
  font-weight: 600;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
}

.generic-small-bold-italic-text {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 0.7vw;
  font-style: italic;
  font-weight: 600;
}

.generic-button-container {
  height: 100%;
  width: 7%;
  border-radius: 50px;
  border: white 1px solid;
  padding: 1.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}