.main-container-main-container {
    height: 78vh;
    overflow: hidden;
    align-items: center;
    display: flex;
    color: white;
    flex-direction: column;
    box-sizing: border-box;
    flex-direction: column;
    background: url("../../assets/bassnet-background.svg") repeat;
    position: relative;
}

.main-container-pannel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 78%;
    height: 100%;
    gap: 0.4rem;
}

.main-container-audio-inputs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 68%;
    height: 100%;
    gap: 0.4rem;
    padding: 0.5em 0;
    overflow-y: scroll;
    overflow-x: visible;
}

.main-container-pannel-controls-basslines-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35%;
    height: 100%;
    gap: 0.1rem;
    padding: 0.5em 0;
}

.main-container-controls-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 47.5%;
}

.main-container-basslines-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 53%;
}

.controls-container-dd-new-bassline-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 50%;
}

.controls-container-transform-guidance-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    height: 50%;
}
