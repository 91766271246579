.player-border-container {
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
    margin-left: -5vw;
}

.player-main-container {
    display: flex;
    align-items: center;
    height: 95%;
    width: 100%;
    background: #1A191E;
    border-radius: 10px;
    z-index: 1;
    padding: 0 0.6em;
    gap: 0.2em;
}

.audio-player-controls-container {
    display: flex;
    align-items: center;
    background: #1A191E;
    height: 100%;
    border-radius: 25px;
    gap: 0.3em;
    box-sizing: border-box;
}

@media screen and (max-width: 950px) {
    .audio-player-controls-container {
        justify-content: center;
        width: 12%;
    }
}

.audio-player-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1A191E;
    border: none;
    cursor: pointer;
    width: 1.8vw;
    height: 1.8vw;
}

.audio-fd-cd-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1A191E;
    border: none;
    cursor: pointer;
}

.audio-player-time-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1A191E;
    height: 100%;
    width: 58%;
    border-radius: 25px;
    gap: 0.18em;
    box-sizing: border-box;
}

.audio-player-time-indicator-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
}

/* Audio progress bar */

.audio-progress-bar-container {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

input[type=range].audio-progress-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-appearance: none;     /*nécessaire pour Chrome */
    padding: 0;                   /* nécessaire pour IE */
    font: inherit;                /* même rendu suivant font document */
    outline: none;
    color: #000000;               /* sert pour couleur de référence, via currentColor, pour le curseur */
    background: #FFFFFF;             /* sert pour couleur de fond de la zone de déplacement */
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;       /* même modèle de boîte pour tous */
    transition: opacity .2s;
    cursor: pointer;
    height: 2px;
    width: 100%;
    border-radius: 10px;
}


/* Chrome, Safari, Edge, Opera */
/* la zone de déplacement */
input[type=range].audio-progress-bar::-webkit-slider-runnable-track {
    height: 100%;
    border: none;
    border-radius: 20%;
    background-color: transparent;  /* supprimé définie sur l'input */
}

/* le curseur */
input[type=range].audio-progress-bar::-webkit-slider-thumb {
    -webkit-appearance: none;       /* également nécessaire sur le curseur */
    height: 12px;                /* s'adapte à la hauteur de l'input */
    width: 12px;
    border: none;
    border-radius: 50%;               /* pris en compte sur Webkit et Edge */
    background: white;       /* pris en compte sur Webkit only */
}


/* Firefox */
/* la zone de déplacement */
input[type=range].audio-progress-bar::-moz-range-track {
    height: 100%;
    border: none;
    border-radius: 20%;
    background-color: transparent;  /* supprimé définie sur l'input */
}

/* le curseur */
input[type=range].audio-progress-bar::-moz-range-thumb {
    -moz-appearance: none;          /* également nécessaire sur le curseur */
    height: 12px;                /* s'adapte à la hauteur de l'input */
    width: 12px;
    border: none;
    border-radius: 50%;               /* pris en compte sur Webkit et Edge */
    background: white;       /* pris en compte sur Webkit only */
}
/* */

.volume-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 22%;
    height: 100%;
    gap: 0.2em;
    margin-left: 2%;
}

.volume-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--light-grey);
    border-radius: 50%;
    width: 1.8vw;
    height: 1.8vw;
    cursor: pointer;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
}

.volume-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 100%;
    cursor: pointer;
}

.player-elements-separator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.1rem;
    height: 100%;
    font-size: 1.5rem;
}
