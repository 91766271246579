.basslines-container-border-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0.3rem;
    overflow: hidden;
    overflow-x: visible;
    border-radius: 10px;
}

.basslines-container-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-grey);
    border-radius: 10px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    flex-direction: column;
    position: relative;
}

.basslines-no-basslines-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5vw;
}

.basslines-no-basslines-text {
    display: flex;
    padding: 0.35vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    border-radius: 1vw;
    background-color: var(--black);
    font-size: 0.6vw;
    font-weight: 800;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
}

.basslines-container-upperside {
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.basslines-type-container {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 6px;
    background-color: var(--darkSalmon);
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.8vw;
}

.basslines-container-content-container {
    width: 100%;
    height: 78%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    padding: 0.3rem;
    padding-bottom: 0rem;
    padding-right: 0.15rem;
}

.table-cell-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    padding: 0.1rem;
}

.basslines-table-body-container {
    width: 100%;
    /* height: 290px; */
    /* min-height: 290px; */
    display: block;
    overflow-y: scroll;
    overflow-x: visible;
    cursor: pointer;
    position: relative;
    will-change: transform;
}
