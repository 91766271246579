.waveform-container {
  /* width: 100vw; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
  /* background-color: black; */
  border-radius: 10px;
  /* box-sizing: border-box; */
}

.overlayed-waveform-container {
  /* display: block; */
  height: 100%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

/* Audio progress bar */

.waveform-progress-cursor-bar-container {
    width: 200%;
    /* use width to zoom input */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
}

input[type=range].progress-cursor-input-bar {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#A61932, #A61932);
  background-size: 70% 100%;
  background-repeat: no-repeat;
  opacity: 0.62;
}

/* Chrome, Safari, Edge, Opera */
input[type=range].progress-cursor-input-bar::-webkit-slider-runnable-track {
  border-radius: 10px 10px 10px 10px;
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
  height: 100%;
}

input[type=range].progress-cursor-input-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  height: 100%;
  width: 0.01vw;
}

/* Firefox */
input[type=range].progress-cursor-input-bar::-moz-range-track {
  border-radius: 10px 10px 10px 10px;
  -moz-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
  height: 100%;
}

input[type=range].progress-cursor-input-bar::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  height: 100%;
  width: 0.1vw;
  border: none;
}

/* */
