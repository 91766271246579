.bassnet-audio-track-border-container {
    height: 100%;
    box-sizing: border-box;
    margin-left: 0.3vw;
    margin-bottom: 0.2vw;
}

.bassnet-audio-track-main-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    box-sizing: border-box;
    z-index: 1;
}

.bassnet-audio-track-active-container {
    width: 100%;
    display: flex;
    margin: 0;
    object-fit: cover;
    height: auto;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: flex-end;
}

.bassnet-no-audio-tracks-container {
    width: 95.6%;
    height: 100%;
    background: rgba(40, 39, 44, 0.70);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    border-radius: 10px;
    gap: 0.5vw;
}

.bassnet-no-audio-tracks-text {
    display: flex;
    padding: 0.35vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    border-radius: 1vw;
    background-color: var(--black);
    font-size: 0.6vw;
    font-weight: 800;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
}

.bassline-audio-track-internal-components-container {
    width: 95.6%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--dark-grey);
    border-radius: 10px;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    flex-direction: column;
}

.bassline-audio-track-side-components-container {
    display: flex;
    width: 5%;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-grey);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-left: -0.5vw;
    padding: 0.3vw;
    padding-left: 0.8vw;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    flex-direction: column;
}

.bassline-audio-track-side-components-container:hover {
    background-color: var(--light-grey);
    cursor: pointer;
}

.bassnet-audio-track-props-container {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3vw;
}

.bassnet-audio-track-props-base-props-container {
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0.3vw;
    gap: 0.7vw;
}

.bassnet-audio-track-time-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.5vw;
    color: #535256;
}

.bassnet-audio-track-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 12vw;
    background-color: var(--black);
    padding: 0.4vw;
    font-size: 0.7vw;
    border-radius: 0.5vw;
    color: white;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
}

.bassnet-audio-track-title-container:hover {
    background-color: var(--light-grey);
}

.bassnet-audio-track-square-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black);
    padding: 0.4vw;
    width: 1.7vw;
    font-size: 0.7vw;
    border-radius: 0.5vw;
    color: white;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
}

.bassnet-audio-track-square-button:hover {
    background-color: var(--light-grey);
    cursor: pointer;
}

.bassnet-audio-track-knob-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-top: 0.3vw;
    font-size: 0.6vw;
}

.bassnet-audio-track-knob {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.2vw;
}

.bassnet-audio-track-props-volume-fold-container {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0.5vw;
    gap: 0.4vw;
}

.bassnet-audio-track-volume-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 0.3vw;
    height: 100%;
    width: 6vw;
}

.bassnet-audio-track-volume-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    cursor: pointer;
}

.bassnet-audio-track-volume-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4vw;
    height: 100%;
    cursor: pointer;
}

.bassnet-audio-track-folding-icon {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    background-color: var(--light-grey);
    border-radius: 10vw;
}

.bassnet-audio-track-remove-icon {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    background-color: var(--light-grey);
    border-radius: 10vw;
}

.bassnet-audio-track-waveform-container {
    width: 100%;
    height: 9vh;
    overflow-y: auto;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    gap: 0.5rem;
    transition: all 0.5s ease-out;
}