.audio-inputs-header-border-container {
    box-sizing: border-box;
    margin-left: 0.3vw;
}

.audio-inputs-header-main-container {
    width: 95.6%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--light-grey);
    border-radius: 10px;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    flex-direction: row;
    padding: 0.04rem;
    padding-right: 0.5vw;
}

.audio-inputs-header-title-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--darkSalmon);
    border-radius: 0.5vw;
    font-size: 0.6vw;
    font-weight: 600;
    color: white;
    box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
    gap: 0.3vw;
    padding: 0.45vw;
    padding-left: 0.4vw;
    padding-right: 0.6vw;
}

.audio-inputs-header-conatiner-props-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.6vw;
}

.audio-inputs-header-zoom-buttons-container {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.05vw;
}

.audio-inputs-header-fold-all-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 4vw;
    gap: 0.3vw;
    padding: 0.2vw;
    border-radius: 0.7vw;
    background-color: var(--dark-grey-bis);
    font-size: 0.6vw;
    font-weight: 600;
    color: white;
    padding-left: 0.3vw;
    padding-right: 0.3vw;
}

.audio-inputs-header-fold-all-button-container:hover {
    background-color: var(--dark-grey);
    cursor: pointer;
}
