.transform-border-container {
    width: 60%;
    height: 100%;
    box-sizing: border-box;
    padding: 0.3rem;
}

.transform-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--dark-grey);
    border-radius: 10px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    position: relative;
    flex-direction: column;
}

.transform-upperside {
    width: 100%;
    height: 2.2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.transform-type-container {
    width: 32%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 6px;
    background-color: var(--darkSalmon);
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.8vw;
}

.transform-container-type-container {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.transform-type-name {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.65vw;
}

/* custom font size for <p> */
.transform-type-name p {
    font-size: 7px;
}

.transform-content-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}