.code-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 20vw;
    height: 25vh;
    background-color: var(--dark-grey);
    gap: 5%;
}

.code-modal-input {
    width: 50%;
    height: 15%;
    outline: none;
    box-shadow: 0 2px 2px 2px var(--black);
    border-radius: 5px;
    padding-left: 3%;
}