.generate-button-border-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 57%;
    height: 100%;
    box-sizing: border-box;
    padding: 0.3rem 0rem;
}

.generate-button-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    width: 100%;
    height: 100%;
    background-color: var(--dark-grey);
    border-radius: 150px;
    padding: 0.4rem;
    box-sizing: border-box;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    transition: 400ms;
}

.generate-button-progress-bar-container {
    width: 100%;
    height: 100%;
    rotate: -90deg;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    opacity: 0.5;
    border-radius: 150px;
    overflow: hidden;
    transition: ease-in-out 0.5s;
}

.generate-button-background-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    background: radial-gradient(116.62% 121.52% at 10.44% 21.52%, #353437 13.22%, #1A191E 82.3%);
    color: white;
    font-weight: 900;
    border-radius: inherit;
    font-size: 1.5vw;
    cursor: pointer;
    text-transform: uppercase;
    gap: 0.1rem;
}

.generate-button-background-border {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-sizing: border-box;
    padding: 0.1rem;
    border-radius: inherit;
    box-shadow: 2px 2px 1px rgb(0 0 0 / 42%);
    background: linear-gradient(220deg, var(--light-grey) 30%, var(--black) 50%);

    cursor: pointer;
    transition: 100ms;
    width: 100%;
    height: 100%;
    transition: 400ms;
}

.generate-button-background-border:hover {
    background: linear-gradient(72deg, var(--light-grey), var(--black) 70%);
}

.generate-button-background-border:active {
    scale: 0.98
}

.generate-button-icon-container {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    outline: white solid 4px;
    margin-bottom: 2%;
    transition: ease-in-out 2s;
    animation: fadeIn 1s ease-in-out;
}

.generate-button-icon-container.fadeOut {
    animation: fadeOut 1s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

@keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
}

@media screen and (max-width: 1366px) and (orientation: portrait) {
    .generate-button-border-container {
        width: 100%;
        height: 100%;
        flex-direction: column;
        padding: 0 1% 1% 1%;
    }

    .generate-button-main-container {
        border-radius: 0px 0px 10px 10px;
    }

    .generate-button-background-container {
        font-size: 18px;
        flex-direction: column;
        /*gap: 0.5rem;*/
    }

    .generate-button-left-side {
        width: 100%;
        height: 100%;
    }

    .generate-button-right-side {
        flex-direction: row;
        width: 100%;
        height: 20%;
    }
}

@media screen and (max-height: 430px) and (orientation: landscape) {
    .generate-button-border-container {
        width: 100%;
        height: 100%;
        flex-direction: row;
        padding: 0;
    }

    .generate-button-main-container {
        border-radius: 10px 0px 0px 10px;
    }

    .generate-button-background-container {
        font-size: 16px;
        flex-direction: column;
    }

    .generate-button-left-side {
        width: 75%;
        height: 94%;
    }

    .generate-button-right-side {
        flex-direction: column;
        width: 25%;
        height: 94%;
    }
}

@media screen and (max-width: 1366px) and (orientation: landscape) {
    .generate-button-border-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        padding: 0;
        align-items: stretch;
    }
    
    .generate-button-main-container {
        border-radius: 10px 0px 10px 10px;
    }
    
    .generate-button-left-side {
        width: 90%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    
    
    .generate-button-right-side {
        width: 10%;
        height: 50%;
        display: flex;
        flex-direction: column;
        background-color: transparent;
        gap: 0.5rem;
        background-color: var(--black);
        align-items: center;
    }
}