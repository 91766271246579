.tracks-list-border-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tracks-list-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.1vw;
}
