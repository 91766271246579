.rotary-slider-container-border-container {
    display: flex;
    flex: 1;
    height: 100%;
    width: 45%;
    box-sizing: border-box;
    padding: 0.3rem;
}

.rotary-slider-container-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10%;
    width: 100%;
    height: 100%;
    background-color: var(--dark-grey);
    border-radius: 10px;
    box-shadow: 2px 4px 2px rgb(0 0 0 / 42%);
    flex-direction: column;
    gap: 0.5rem;
}

.rotary-slider-container-type-container:hover {
    font-weight: 700;
}

.rotary-slider-container-title {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9vw;
    margin-right: 5%;
    margin-bottom: 0.5rem;
}

.rotary-slider-container-top-part {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 10%;
}

.rotary-slider-proprety-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.7vw;
    color: #696972;
}