.tooltip-title-container {
  border-radius: 10%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.5rem;
}

.tootltip-title-text {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.tooltip-title {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 6rem;
  align-items: center;
  background-color: var(--dark-grey);
  padding: 5%;
  border-radius: 10px;
  justify-content: space-around;
  margin: 10%;
  margin-top: 0;
  margin-left: 0;
  padding: 10%;
  gap: 0.5rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  border-radius: 10px;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
  border-radius: 5px;
  width: 2rem;
  padding: 1%;
  text-align: end;
}

input[type="number"]::-webkit-textfield-decoration-container {
  appearance: none;
  color: #fff;
  font-family: "Fira Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
}

input[type="checkbox"] {
  appearance: none;
  background-color: var(--dark-grey);
  width: 1rem;
  height: 1rem;
  border-radius: 5px;
  box-shadow: 0px 2px 5px var(--dark-grey);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.8rem;
  height: 1rem;
  border-radius: 5px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: rgb(26, 25, 30);
}

input[type="checkbox"]:checked::before {
  clip-path: polygon(14% 55%, 0 55%, 50% 85%, 85% 30%, 95% 10%, 43% 62%);
  font-size: 0.8rem;
  background-color: white;
  transform: scale(1);
}

.checkbox {
  background-color: var(--dark-grey);
  width: 1vw;
}

.checkbox-text {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

.checkbox-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.5rem;
  margin: 2%;
}

.download-tooltip-export-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 0.5rem;
  padding-bottom: 4%;
}

.download-tooltip-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}

.download-tooltip-title-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 4%;
  padding: 4%;
}

.download-tooltip-title {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  text-decoration-line: underline;
}

.download-tooltip-export-background {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 0.5rem;
  padding: 4%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 20px;
  background-color: var(--dark-grey);
  cursor: pointer;
}

.download-tooltip-export-background:hover {
  background-color:rgb(26, 25, 30);
}

.download-tooltip-export {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}

.download-tooltip-main-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 10%;
  margin-bottom: 5%;
  margin-left: 10%;
}

.download-tooltip-main {
  height: 100%;
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}

.download-tooltip-footer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.basslines-lowerside-border-container {
  width: 100%;
  height: 12%;
  display: flex;
  flex-direction: column;
}

.basslines-lowerside-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem;
  padding-left: 1vw;
  padding-right: 1vw;
  background-color: var(--dark-grey);
  border-radius: 10px;
  box-shadow: 2px -2px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  flex-direction: row;
}

.footer-switch-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  white-space: pre;
  cursor: pointer;
  /* flex: 1; */
  font-size: 0.7vw;
}

.bassline-footer-download-all-button-container {
  height: 70%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--light-grey);
  padding-left: 0.6rem;
  border-radius: 29.5px;
  box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25);
  gap: 0.3rem;
  cursor: pointer;
}

.bassline-footer-download-all-button-title {
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6vw;
  font-weight: 200;
  color: var(--white);
}

.bassline-footer-download-all-button-icon {
  height: 100%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-grey);
  box-shadow: -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.footer-arrows-container {
  /* width: 15%; */
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
